import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import "./App.scss";
// import "./index.css";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Login
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
});

// AutoLogin
const AutoLogin = Loadable({
  loader: () => import("./views/Pages/Login/AutoLogin"),
  loading
});

// 404
const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404/Page404"),
  loading
});

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

const PublicView = Loadable({
  loader: () => import("./views/Calendar/Viewdata"),
  loading
});

const PublicRegister = Loadable({
  loader: () => import("./views/Public/Register"),
  loading
});


const PublicVieweva = Loadable({
  loader: () => import("./views/Public/Vieweva"),
  loading
});


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route
              exact
              path="/calendar/views/:genid"
              name="Event detail"
              component={PublicView}
            />
             <Route
              exact
              path="/public/register/:genid"
              name="Public register"
              component={PublicRegister}
            />
            <Route
              exact
              path="/public/vieweva/:traineeid/:manageevaluid/:empid"
              name="Public Vieweva"
              component={PublicVieweva}
            />
            <Route
              exact
              path="/trainingmanage/approvecourse/assess/:trainigid/vieweva/:traineeid/:manageevaluid/:empid/:userid/:sha1password"
              name="Public Vieweva"
              component={AutoLogin}
            />
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route path="/" name="Home" component={DefaultLayout} />
            <Route path="/page404" component={Page404} />
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
