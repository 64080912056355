import {
  USER_EMAIL_LOGIN_FAIL,
  USER_EMAIL_LOGIN_SUCCESS,
  USER_LOGOUT,
  ADD_NOTIF,
  ADD_NOTIF_COUNT,
  INITIAL_YEAR
} from "../actions/types";
import { adJustMenu } from "../helper/menu";

const initState = {
  token: "",
  userData: {},
  menu: [],
  menuPermit: {},
  notif: [],
  countNoti: 0
};
export default (state = initState, action) => {
  switch (action.type) {
    case USER_EMAIL_LOGIN_FAIL:
      return { ...state, token: "", userData: {} };
    case USER_EMAIL_LOGIN_SUCCESS:
      const { items, menuPermit } = adJustMenu(action.userData);
      return {
        ...state,
        token: action.token,
        userData: action.userData,
        menu: items,
        menuPermit
      };
    case ADD_NOTIF:
      return {
        ...state,
        notif: action.notif
      };
    case ADD_NOTIF_COUNT:
      return {
        ...state,
        countNoti: action.countNoti
      };
    case INITIAL_YEAR:
      return {
        ...state,
        year: action.year
      };
    case USER_LOGOUT:
      return {
        token: "",
        userData: {},
        menu: [],
        menuPermit: {},
        notif: []
      };
    default:
      return state;
  }
};
