import { apiPathReport } from "./configs/conf";

export default {
  items: [
    {
      name: "Calendar",
      url: "/calendar",
      icon: "icon-calendar",
      id: 0,
      public: true
    },
    {
      name: "ลงทะเบียนผู้อบรม",
      url: "/register/registration",
      icon: "icon-note",
      id: 1
    },
    {
      name: "จัดการฝึกอบรม",
      url: "/trainingmanage",
      icon: "fa fa-flask",
      id: 2,
      children: [
        {
          name: "ร่างหลักสูตร",
          url: "/trainingmanage/listdrafttranning",
          icon: "fa fa-circle",
          id: 15
        },
        {
          name: "ข้อมูลแผนฝึกอบรม",
          url: "/trainingmanage/Infoplan",
          icon: "fa fa-circle",
          id: 16
        },
        {
          name: "จัดกิจกรรมหลักสูตร",
          url: "/trainingmanage/list_activity",
          icon: "fa fa-circle",
          id: 17
        },
        {
          name: "ขออนุมัติกิจกรรมหลักสูตร",
          url: "/trainingmanage/approvecourse",
          icon: "fa fa-circle",
          id: 74
        },
        {
          name: "ค้นหาหลักสูตร",
          url: "/trainingmanage/search",
          icon: "fa fa-circle",
          id: 108
        },
        {
          name: "อนุมัติหัวข้อหลักสูตร",
          url: "/trainingmanage/approvetopic",
          icon: "fa fa-circle",
          id: 18
        },
        {
          name: "ข้อมูลงบประมาณ",
          url: "/trainingmanage/bugget",
          icon: "fa fa-circle",
          id: 19
        },
        {
          name: "Catalog วิชา",
          url: "/trainingmanage/catalog",
          icon: "fa fa-circle",
          id: 20
        },
        {
          name: "ข้อมูลพื้นฐานค่าใช้จ่าย",
          url: "/trainingmanage/expenses",
          icon: "fa fa-circle",
          id: 20
        },
        {
          name: "ข้อมูลวิทยากร",
          url: "/human/lecturer",
          icon: "fa fa-circle",
          id: 109
        },
        {
          name: "รายงาน",
          url: "/trainingmanage/report",
          icon: "fa fa-circle",
          id: 22
        }
      ]
    },
    {
      name: "จัดการด้านการเงิน",
      url: "/financial",
      icon: "fa fa-usd",
      id: 7,
      children: [
        {
          name: "รายการข้อมูลค่าใช้จ่าย",
          url: "/financial/costlist",
          icon: "fa fa-circle",
          id: 25
        },
        {
          name: "ข้อมูลรหัสบัญชี",
          url: "/financial/account",
          icon: "fa fa-circle",
          id: 26
        },
        {
          name: "ข้อมูลรหัสศูนย์ต้นทุน",
          url: "/financial/costcenter",
          icon: "fa fa-circle",
          id: 27
        },
        {
          name: "เลขที่ใบส่งงาน IO",
          url: "/financial/iolist",
          icon: "fa fa-circle",
          id: 28
        },
        {
          name: "ข้อมูลบัตรเครดิต",
          url: "/financial/creditcard",
          icon: "fa fa-circle",
          id: 29
        },
        {
          name: "ประเภทการเบิกค่าใช้จ่าย",
          url: "/financial/costtype",
          icon: "fa fa-circle",
          id: 30
        },
        {
          attributes: {
            target: "_blank",
            rel: "noreferrer noopener",
            className: "unactive"
          },
          name: "รายงานค่าใช้จ่ายต่อคน",
          url:
            apiPathReport() +
            "Viewer?ReportID=F20FC416-41F5-497B-A8AD-CEAA3BE63747",
          icon: "fa fa-circle",
          id: 95
        },
        {
          name: "รายงาน",
          url: "/financial/report",
          icon: "fa fa-circle",
          id: 90
        }
      ]
    },
    {
      name: "จัดการแบบประเมินผล",
      url: "/assessment",
      icon: "fa fa-address-book-o",
      id: 4,
      children: [
        {
          name: "สร้างแบบประเมิน",
          url: "/assessment/create",
          icon: "fa fa-circle",
          id: 31
        },
        {
          name: "ประเภทแบบประเมิน",
          url: "/assessment/evatype",
          icon: "fa fa-circle",
          id: 32
        },
        {
          name: "รายงาน",
          url: "/assessment/create/report",
          icon: "fa fa-circle",
          id: 105
        }
      ]
    },
    {
      name: "จัดการใบประกาศนียบัตร",
      url: "/certification",
      icon: "fa fa-shield",
      id: 3,
      children: [
        {
          name: "จัดการ Template",
          url: "/certification/template",
          icon: "fa fa-circle",
          id: 33
        },
        {
          name: "ออกใบประกาศนียบัตร",
          url: "/certification/createcert",
          icon: "fa fa-circle",
          id: 34
        },
        {
          attributes: {
            target: "_blank",
            rel: "noreferrer noopener",
            className: "unactive"
          },
          name: "ประกาศนียบัตร(ฝึกงาน)",
          url:
            apiPathReport() +
            "Viewer?ReportID=DD46AEC1-6B1D-4D5A-8724-88C2D232975E",
          icon: "fa fa-circle",
          id: 96
        },
        {
          name: "จัดการลายเซนต์",
          url: "/certification/signature",
          icon: "fa fa-circle",
          id: 35
        }
      ]
    },
    {
      name: "สิทธิ์การใช้งานระบบ",
      url: "/role",
      icon: "cui-user",
      id: 12
    },
    {
      name: "จัดการทุนการศึกษา",
      url: "/scholarship",
      icon: "fa fa-book",
      id: 5
    },
    {
      name: "จัดการหน่วยงานลูกค้า",
      url: "/managecustomer",
      icon: "fa fa-sitemap",
      id: 9,
      children: [
        {
          name: "ข้อมูลโครงสร้างหน่วยงานประปา",
          url: "/managecustomer/department",
          icon: "fa fa-circle",
          id: 36
        },
        {
          name: "ข้อมูลประเภทหน่วยงาน",
          url: "/managecustomer/departmenttype",
          icon: "fa fa-circle",
          id: 37
        },
        {
          name: "ข้อมูลประเภทธุรกิจ",
          url: "/managecustomer/businesstype",
          icon: "fa fa-circle",
          id: 38
        },
        {
          name: "ชนิดแหล่งน้ำดิบ",
          url: "/managecustomer/water",
          icon: "fa fa-circle",
          id: 39
        },
        {
          name: "ข้อมูลกลุ่มปัญหา",
          url: "/managecustomer/probremtype",
          icon: "fa fa-circle",
          id: 40
        },
        {
          name: "ข้อมูลประเภทความร่วมมือ",
          url: "/managecustomer/coperationtype",
          icon: "fa fa-circle",
          id: 41
        },
        {
          name: "รายงาน",
          url: "/managecustomer/report",
          icon: "fa fa-circle",
          id: 93
        }
      ]
    },
    {
      name: "จัดการยุทธศาสตร์",
      url: "/strategic",
      icon: "fa fa-podcast",
      id: 8,
      children: [
        {
          name: "ข้อมูลยุทธศาสตร์",
          url: "/strategic/list",
          icon: "fa fa-circle",
          id: 42
        },
        {
          name: "ข้อมูลตัวชี้วัด",
          url: "/strategic/indicators",
          icon: "fa fa-circle",
          id: 43
        },
        {
          name: "โครงการ",
          url: "/strategic/project",
          icon: "fa fa-circle",
          id: 44
        },
        {
          name: "ข้อมูล Balance Score Card",
          url: "/strategic/bsc",
          icon: "fa fa-circle",
          id: 45
        },
        {
          attributes: {
            target: "_blank",
            rel: "noreferrer noopener",
            className: "unactive"
          },
          name: "รายงาน",
          url: apiPathReport() +
          "Viewer?ReportID=88303DA9-D4ED-4EE5-BCC0-BA3F325B24AD",
          icon: "fa fa-circle",
          id: 106
        }
      ]
    },
    {
      name: "การพัฒนารายบุคคล",
      url: "/idp",
      icon: "fa fa-clipboard",
      id: 13,
      children: [
        {
          name: "วางแผนพัฒนารายบุคคล",
          url: "/idp/select",
          icon: "fa fa-circle",
          id: 46
        },
        {
          name: "รับทราบแผนพัฒนารายบุคคล",
          url: "/idp/viewbystaff",
          icon: "fa fa-circle",
          id: 47
        },
        {
          name: "เปรียบเทียบรายการสมรรถนะเพื่อความก้าวหน้า",
          url: "/idp/levelup",
          icon: "fa fa-circle",
          id: 67
        },{
          name: "Competency Matrix",
          url: "/idp/competencymatrix",
          icon: "fa fa-circle",
          id: 66
        },
        {
          name: "Competency",
          url: "/idp/competency",
          icon: "fa fa-circle",
          id: 68
        },
        {
          name: "Catalog วิชา",
          url: "/trainingmanage/catalog",
          icon: "fa fa-circle",
          id: 20
        },
        {
          name: "รายงาน",
          url: "/idp/report",
          icon: "fa fa-circle",
          id: 103
        },
        {
          attributes: {
            target: "_blank",
            rel: "noreferrer noopener",
            className: "unactive"
          },
          name: "รายงานผลการพัฒนารายบุคคล ประจำปีงบประมาณ",
          url:  apiPathReport() +
          "Viewer?ReportID=89e3a205-4dad-4779-b223-73c284c17552",
          icon: "fa fa-circle",
          id: 104
        }
      ]
    },
    {
      name: "ข้อมูลพื้นฐาน",
      url: "/master",
      icon: "fa fa-tasks",
      id: 11,
      children: [
        {
          name: "ข้อมูลประเทศ",
          url: "/master/country",
          icon: "fa fa-circle",
          id: 48
        },
        {
          name: "ข้อมูลจังหวัด",
          url: "/master/province",
          icon: "fa fa-circle",
          id: 49
        },
        {
          name: "ข้อมูลอำเภอ",
          url: "/master/distric",
          icon: "fa fa-circle",
          id: 50
        },
        {
          name: "ข้อมูลตำบล",
          url: "/master/subdistric",
          icon: "fa fa-circle",
          id: 51
        },
        {
          name: "ข้อมูลสถานที่อบรม",
          url: "/master/trainingplace",
          icon: "fa fa-circle",
          id: 52
        },
        {
          name: "ข้อมูลสถานบันการศึกษา",
          url: "/master/eduplace",
          icon: "fa fa-circle",
          id: 53
        },
        {
          name: "ข้อมูลพื้นฐานอื่นๆ",
          url: "/master/othermaser",
          icon: "fa fa-circle",
          id: 54
        }
      ]
    },
    {
      name: "องค์ความรู้",
      url: "/knowledge",
      icon: "fa fa-pie-chart",
      id: 6,
      children: [
        {
          name: "Work System",
          url: "/knowledge/worksystem",
          icon: "fa fa-circle",
          id: 55
        },
        {
          name: "ระบบผู้เชี่ยวชาญ",
          url: "/knowledge/expert",
          icon: "fa fa-circle",
          id: 56
        },
        {
          name: "องค์ความรู้",
          url: "/knowledge/knowledge",
          icon: "fa fa-circle",
          id: 58
        },
        {
          name: "Tacit Knowledge",
          url: "/knowledge/tacit",
          icon: "fa fa-circle",
          id: 57
        },
        {
          name: "รายงาน",
          url: "/knowledge/worksystem/report",
          icon: "fa fa-circle",
          id: 107
        }
      ]
    },
    {
      name: "จัดการบุคลากร",
      url: "/human",
      icon: "fa fa-users",
      id: 10,
      children: [
        {
          name: "ข้อมูลหน่วยงาน",
          url: "/human/department",
          icon: "fa fa-circle",
          id: 59
        },
        {
          name: "ข้อมูลตำแหน่งงาน",
          url: "/human/position",
          icon: "fa fa-circle",
          id: 60
        },
        {
          name: "ข้อมูลพนักงาน",
          url: "/human/personal",
          icon: "fa fa-circle",
          id: 61
        },
        {
          name: "ข้อมูลผู้เชี่ยวชาญ",
          url: "/knowledge/expert",
          icon: "fa fa-circle",
          id: 56 // 62 same
        },
        {
          name: "ข้อมูลวิทยากร",
          url: "/human/lecturer",
          icon: "fa fa-circle",
          id: 63
        },
        {
          name: "ข้อมูลกิจกรรมพัฒนาพนักงาน",
          url: "/human/activitypersonal",
          icon: "fa fa-circle",
          id: 64
        },
        {
          name: "ข้อมูลบุคคลภายนอก",
          url: "/human/user",
          icon: "fa fa-circle",
          id: 65
        },
        {
          name: "รายงาน",
          url: "/human/report",
          icon: "fa fa-circle",
          id: 68
        }
      ]
    },
    {
      name: "การตั้งค่าระบบ",
      url: "/setting",
      icon: "fa fa-cogs",
      id: 69,
      children: [
        {
          name: "Config Path",
          url: "/setting",
          icon: "fa fa-circle",
          id: 70
        },
        {
          name: "ประวัติการแก้ไข",
          url: "/setting/historymodify",
          icon: "icon-calculator",
          id: 21
        },
        // {
        //   name: "ตั้งค่า Email",
        //   url: "/setting/email",
        //   icon: "fa fa-circle",
        //   id: 71
        // },
        {
          name: "ตั้งค่าการแจ้งเตือน",
          url: "/setting/notification",
          icon: "fa fa-circle",
          id: 72
        }
      ]
    },
    {
      name: "ประวัติการใช้งานระบบ",
      url: "/history",
      icon: "fa fa-history",
      id: 73
    },
    {
      id: 14,
      url: "/reports",
      name: "จัดการรายงาน",
      icon: "fa fa-list-alt"
    },
    {
      name: "รายงานสำหรับผู้บริหาร",
      url: "/executiveReport",
      icon: "fa fa-file-text-o",
      id: 115,
      children: [
        {
          name: "รายงานติดตามสถานะการดำเนินการหลักสูตร",
          url: "/executiveReport/TrainingStatusReport",
          icon: "fa fa-circle",
          id: 111
        },
        {
          name: "รายงานสรุปแบบประเมินผลการฝึกอบรมรายหลักสูตร",
          url: "/executiveReport/TrainingSummaryReport",
          icon: "fa fa-circle",
          id: 112
        },
        {
          name: "รายงานสรุปแบบประเมินความพึงพอใจ",
          url: "/executiveReport/SatisfactionSummaryReport",
          icon: "fa fa-circle",
          id: 113
        },
        {
          name: "รายงานสรุปแบบประเมินพฤติกรรม(เชิงความรู้)",
          url: "/executiveReport/BehaviorSummaryReport",
          icon: "fa fa-circle",
          id: 114
        }
      ]
    }
  ]
};
