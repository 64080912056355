import { combineReducers } from "redux";
import auth from "./auth_reducer";
import generation from "./generation_reducer";
import finance from "./finance_reducer";

export default combineReducers({
  auth,
  generation,
  finance
});
