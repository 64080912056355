export const actions = {
  expensesSelectedAdd: "EXPENSES_SELECTED_ADD",
  trainingDetailAdd: "TRAINING_DETAIL_ADD"
};

const initState = {
  expensesSelected: {},
  trainingDetail: {
    TrainingName: ""
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.expensesSelectedAdd:
      return { ...state, expensesSelected: action.expensesSelected };
    case actions.trainingDetailAdd:
      return { ...state, trainingDetail: action.trainingDetail };
    default:
      return state;
  }
};
