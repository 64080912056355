export function serverApi() {
  return 'http://202.170.120.179:5000/'
  return "https://localhost:44354/";
  //return "https://dmwit.mwa.co.th/webapi/";
}

export function apiPathReport() {
  return 'http://202.170.120.179:9090/Home/'
  //return "https://dmwit.mwa.co.th/webapi/Report/home/";
}

export function serverApiOutsource() {
  return "https://www.dinsormaiserver.com/maic/services/api/";
}

export function getToken() {
  return localStorage.token;
}

export function styleIconEdit() {
  return { fontSize: "25px", color: "#08c" };
}

export function styleIconDisable() {
  return { fontSize: "25px", color: "#c8ced3" };
}

export function styleIconcancel() {
  return { fontSize: "25px", color: "red" };
}

export function styleIconDelete() {
  return { fontSize: "25px", color: "#eb2f96" };
}

export function styletxtheader() {
  return { fontSize: "20px" };
}

export function styleRegisterFalse() {
  return { fontSize: "20px", color: "red" };
}

export function styleRegisterTrue() {
  return { fontSize: "20px", color: "#30fb30" };
}
 