import navigation from "./../_nav";

export const adJustMenu = userData => {
  let adjustNav = [];
  let moduleArr = [];
  let menuPermit = {};
  if (!!userData.Menu) {
    const IsView = userData.Menu.filter(v => !!v.IsView || !!v.IsManage); //filter main moduel can view

    moduleArr = IsView.map(v => {
      menuPermit[v.Id] = v;
      return v.Id;
    });

    adjustNav = navigation.items.filter(
      v => moduleArr.includes(v.id) || !!v.public
    ); // check menu and public menu


    adjustNav.forEach(v => {
      // get menu list children
      if (!!v.children) {
        v.children.forEach(c => {
          moduleArr.push(c.Id);
        });
      }
    });

    adjustNav = adjustNav.map(v => ({
      //filter children menu
      ...v,
      children: !!v.children
        ? v.children.filter(c => moduleArr.includes(c.id))
        : null
    }));
  } else {
    adjustNav = navigation.items.filter(
      v => moduleArr.includes(v.id) || !!v.public
    );
  }


  return { items: adjustNav, menuPermit };
};
