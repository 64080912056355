export const actions = {
  receiveMoneyDataAdd: "RECEIVE_MONEY_DATA_ADD",
  borrowMoneyDataAdd: "BORROW_MONEY_DATA_ADD",
  receiveMoneyDataClear: "RECEIVE_MONEY_DATA_CLEAR"
};

const initState = {
  receiveMoneyData: {
    RefuteID: null,
    Remark: "",
    TrainingGenStartDate: null,
    TrainingGenEndDate: null,
    ReceiveDate: null,
    ReceiveDocDate: null,
    GorBorTorDate: null,
    GorGorNgorDate: null
  },
  borrowMoneyData: {}
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.receiveMoneyDataAdd:
      return { ...state, receiveMoneyData: action.receiveMoneyData };
    case actions.receiveMoneyDataClear:
      return { ...state, receiveMoneyData: initState.receiveMoneyData };
    case actions.borrowMoneyDataAdd:
      return { ...state, borrowMoneyData: action.borrowMoneyData };
    default:
      return state;
  }
};
