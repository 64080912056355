export const USER_EMAIL_LOGIN_SUCCESS = "user_email_login_success";
export const USER_EMAIL_LOGIN_FAIL = "user_email_login_fail";
export const USER_LOGOUT = "user_logout";

export const MENU_SUCCESS = "menu_success";
export const MENU_FAIL = "menu_fail";

export const USER_ACTIVE_MENU = 'user_active_menu';
export const ADD_NOTIF = 'add_notif';
export const ADD_NOTIF_COUNT = 'add_notif_count';

export const INITIAL_YEAR = 'initial_year';
